<div class="content-wrapper">
    <div class="content-body">
        <!-- content-header component -->
        <app-content-header [contentHeader]="contentHeader"></app-content-header>

        <!-- Basic Alerts start -->
        <!-- <section id="home-page"> -->
        <!-- <section id="home-page" class="hero image-as-background mb-1"
            style="background-size: cover; background-image: url('https://images.unsplash.com/photo-1621187980880-6e44120b8a95?crop=entropy&dpr=2&fit=crop&fm=jpg&h=700&ixjsv=2.1.0&ixlib=rb-0.3.5&q=50&w=1300');"> -->
        <section id="home-page" class="hero image-as-background mb-1" 
            style="background-size: cover; background-repeat: no-repeat; background-image: url('assets/images/unilord/cool-bg-2.jpg');">
            <div class="row">
                <!-- <div class="col-6">
                    <h3>Assemblage Launching! 🚀</h3>
                    <h1>Discover, collect, and sell extraordinary NFTs</h1>
                    <h4>on the world's first & largest NFT marketplace</h4>
                </div> -->
                <div class="col-sm-12 col-md-6">
                    <div class="hero-container">
                        <p class="hero-top-subtext">world's first & largest NFT marketplace</p>
                        <h1 class="hero-title" style="color: transparent;">Discover, Collect, Sell, Exchange, Mint NFTs</h1>
                        <!-- <a href="#" class="hero-button" title="Click to see more">Explorer</a> -->
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-center">
                    <div class="col-10 mt-md-0 mt-sm-1">
                        <!-- <ngb-carousel class="hero-carousel" *ngIf="carouselImages">
                            <ng-template ngbSlide>
                                <div class="card border-0 text-white"><img src="assets/images/slider/10.jpg"
                                        alt="Card image" class="card-img">
                                    <div class="card-img-overlay bg-overlay">
                                        <h4 class="card-title text-white">Card title</h4>
                                        <p class="card-text"> This is a wider card with supporting text below as a
                                            natural lead-in to additional content. This content is a little bit longer.
                                        </p>
                                        <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small>
                                        </p>
                                    </div>
                                </div>
                                <div class="carousel-caption"></div>
                            </ng-template>
                            <ng-template ngbSlide>
                                <div class="card border-0 text-white"><img src="assets/images/slider/09.jpg"
                                        alt="Card image" class="card-img">
                                    <div class="card-img-overlay bg-overlay">
                                        <h4 class="card-title text-white">Card title</h4>
                                        <p class="card-text"> This is a wider card with supporting text below as a
                                            natural lead-in to additional content. This content is a little bit longer.
                                        </p>
                                        <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small>
                                        </p>
                                    </div>
                                </div>
                                <div class="carousel-caption"></div>
                            </ng-template>
                            <ng-template ngbSlide>
                                <div class="card border-0 text-white"><img src="assets/images/slider/08.jpg"
                                        alt="Card image" class="card-img">
                                    <div class="card-img-overlay bg-overlay">
                                        <h4 class="card-title text-white">Card title</h4>
                                        <p class="card-text"> This is a wider card with supporting text below as a
                                            natural lead-in to additional content. This content is a little bit longer.
                                        </p>
                                        <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small>
                                        </p>
                                    </div>
                                </div>
                                <div class="carousel-caption"></div>
                            </ng-template>
                        </ngb-carousel> -->
                        <ngb-carousel class="hero-carousel" *ngIf="randomPickSlides.length > 0">
                            <ng-template ngbSlide *ngFor="let slide of randomPickSlides">
                                <div class="card border-0 text-white" style="height: 300px;">
                                    <img [src]="slide.banner_image_url" alt="Card image" class="card-img" style="object-fit: cover; height: 100%;">
                                    <div class="card-img-overlay bg-overlay">
                                        <h4 class="card-title text-white text-truncate">{{ slide.name }}</h4>
                                        <p class="card-text"> {{ (slide.description.length>128)? (slide.description | slice:0:128)+'..':(slide.description) }}</p>
                                        <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small>
                                        </p>
                                    </div>
                                </div>
                                <div class="carousel-caption"></div>
                            </ng-template>
                        </ngb-carousel>
                    </div>
                </div>
            </div>
        </section>
        <!-- Basic Alerts end -->
        <!-- Marketplace Artworks -->
        <section id="card-demo-example" class="mt-5">
            <div class="row justify-content-md-center">
                <div class="col-md-auto">
                    <div class="section-title">Featured Artworks</div>
                </div>
            </div>
            <div class="row justify-content-center">
            <div class="col-sm-12 col-md-11">
            <ngu-carousel #featuredCarousel [inputs]="carouselTile" [dataSource]="featuredSlides" class="p-2" style="height: 90%;">
                <ngu-tile *nguCarouselDef="let featuredItem; let fp = index">
                    <div class="card">
                        <div class="asset-image mt-1">
                            <img class="" [src]="featuredItem.assets[0].image_url" style="object-fit: contain; width: auto; height: auto; max-width: 100%; max-height: 100%; border-radius: 5px;" alt="Card image cap">
                        </div>
                        <div class="card-body my-1" style="height: 200px;">
                            <div class="d-md-flex justify-content-between align-items-center mb-1">
                                <div class="media">
                                    <div class="avatar mr-1">
                                        <img [src]="featuredItem.maker.profile_img_url" alt="Avatar" width="42"
                                            height="42">
                                    </div>
                                    <div class="media-body">
                                        <h5 class="mb-0">{{ (featuredItem.maker.user && featuredItem.maker.user.username)? featuredItem.maker.user.username : 'No name' }}</h5>
                                        <small class="text-muted">{{ featuredItem.assets[0].collection? featuredItem.assets[0].collection.created_date : '' }}</small>
                                    </div>
                                </div>
                                <div class="badge badge-pill badge-light-primary">Design</div>
                            </div>
                            <div class="row justify-content-between">
                                <p class="col-md-8 card-title m-0">{{ featuredItem.maker.user.username }}</p>
                                <p class="col-md-4 text-success text-md-right">{{ (featuredItem.sell_orders[0] && featuredItem.sell_orders[0].base_price)? featuredItem.sell_orders[0].base_price / 1e18 : 0}} <span class="h6 text-success">ETH</span></p>
                            </div>
                            <p class="card-text line-clamp">
                                {{ featuredItem.assets[0].description }}
                            </p>
                        </div>
                            <div class="demo-inline-spacing justify-content-center mb-1">
                                <!-- <a href="javascript:void(0)" class="btn btn-outline-primary waves-effect">Go
                                    somewhere</a> -->
                                <button type="button" class="btn btn-primary" rippleEffect [routerLink]="'/bundle-details/' + featuredItem.slug">Buy This</button>
                            </div>
                    </div>
                </ngu-tile>
                <!-- <button NguCarouselPrev class="btn-sm btn-icon btn-outline-primary rounded-circle leftRs1"
                            style="z-index: 999; " [style.opacity]="featuredCarousel.isFirst ? 0.5:1"><span
                                [data-feather]="'arrow-left'"></span></button>
                <button NguCarouselNext class="btn-sm btn-icon btn-outline-primary rounded-circle rightRs1"
                    [style.opacity]="featuredCarousel.isLast ? 0.5:1"><span
                        [data-feather]="'arrow-right'"></span></button> -->
                <ul class="myPoint" NguCarouselPoint>
                    <li *ngFor="let j1 of featuredCarousel.pointNumbers; let j2 = index"
                        [class.active]="j1==featuredCarousel.activePoint" (click)="featuredCarousel.moveTo(j1)"
                        [style.background]="'url(' + carouselTileItems[j1] + ')'">
                    </li>
                </ul>
            </ngu-carousel>
            </div>
            </div>
            <!-- <div class="row match-height justify-content-center p-3">
                <div class="col-md-6 col-lg-4">
                    <div class="card">
                        <img class="card-img-top" src="assets/images/slider/04.jpg" alt="Card image cap">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center mb-1">
                                <div class="media">
                                    <div class="avatar mr-1">
                                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" width="42"
                                            height="42">
                                    </div>
                                    <div class="media-body">
                                        <h5 class="mb-0">Mittie Evans</h5>
                                        <small class="text-muted">Updated 12m ago</small>
                                    </div>
                                </div>
                                <div class="badge badge-pill badge-light-primary">Design</div>
                            </div>
                            <div class="row justify-content-between">
                                <h3 class="col-md-8 card-title">Pink Time</h3>
                                <h4 class="col-md-4 text-success">25 <span class="h6 text-success">ETH</span></h4>
                            </div>
                            <p class="card-text">
                                Some quick example text to build on the card title and make up the bulk of the card's
                                content.
                            </p>
                            <div class="demo-inline-spacing">
                                <a href="javascript:void(0)" class="btn btn-outline-primary waves-effect">Go
                                    somewhere</a>
                                <button type="button" class="btn btn-primary" rippleEffect>Buy This</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="card">
                        <img class="card-img-top" src="assets/images/slider/03.jpg" alt="Card image cap">
                        <div class="card-body">
                            <h4 class="card-title">Card title</h4>
                            <p class="card-text">
                                Some quick example text to build on the card title and make up the bulk of the card's
                                content.
                            </p>
                            <div class="demo-inline-spacing">
                                <a href="javascript:void(0)" class="btn btn-outline-primary waves-effect">Go
                                    somewhere</a>
                                <button type="button" class="btn btn-primary" rippleEffect>Primary</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="card">
                        <img class="card-img-top" src="assets/images/slider/02.jpg" alt="Card image cap">
                        <div class="card-body">
                            <h4 class="card-title">Card title</h4>
                            <p class="card-text">
                                Some quick example text to build on the card title and make up the bulk of the card's
                                content.
                            </p>
                            <div class="demo-inline-spacing">
                                <a href="javascript:void(0)" class="btn btn-outline-primary waves-effect">Go
                                    somewhere</a>
                                <button type="button" class="btn btn-primary" rippleEffect>Primary</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </section>
        <!-- Marketpalce Artworks End-->
        <!-- Marketplace Artist -->
        <!-- Marketpalce Artist End-->
        <!-- Carousel Test -->
        <!-- NG-Carousel -->
        <!-- <ngu-carousel #myCarousel [inputs]="carouselTile" [dataSource]="carouselItems" (carouselLoad)="carouselTileLoad($event)"> -->
        <section>
            <div class="row justify-content-md-center">
                <div class="col-md-auto">
                    <div class="section-title">Artists & Collections</div>
                </div>
            </div>
            <div class="row justify-content-md-center">
                <div class="col-sm-12 col-md-10">
                    <ngu-carousel #myCarousel [inputs]="carouselTile" [dataSource]="collectionSlides" class="p-2" style="height: 90%;">
                        <ngu-tile *nguCarouselDef="let item; let j = index">
                            <!-- <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title">Card title</h4>
                                    <h6 class="card-subtitle text-muted">Support card subtitle</h6><img
                                        [src]="item" alt="Card image cap" class="img-fluid my-2">
                                    <p class="card-text">Bear claw sesame snaps gummies chocolate.</p><a
                                        href="javascript:void(0)" class="card-link pink">Card link</a><a
                                        href="javascript:void(0)" class="card-link pink">Another link</a>
                                </div>
                            </div> -->
                            <!-- <div class="card card-profile">
                                <img [src]="item.background" alt="Profile Cover Photo" class="img-fluid card-img-top">
                                <div class="card-body">
                                    <div class="profile-image-wrapper">
                                        <div class="profile-image">
                                            <div class="avatar"><img [src]="item.avatar" alt="Profile Picture"></div>
                                        </div>
                                    </div>
                                    <h3>{{item.artist}}</h3>
                                    <h6 class="text-muted">Malaysia</h6>
                                    <div class="badge badge-light-primary profile-badge">Pro Level</div>
                                    <hr class="mb-2">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div>
                                            <h6 class="text-muted font-weight-bolder">Followers</h6>
                                            <h3 class="mb-0">10.3k</h3>
                                        </div>
                                        <div>
                                            <h6 class="text-muted font-weight-bolder">Projects</h6>
                                            <h3 class="mb-0">156</h3>
                                        </div>
                                        <div>
                                            <h6 class="text-muted font-weight-bolder">Rank</h6>
                                            <h3 class="mb-0">23</h3>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="card card-profile">
                                <!-- <img [src]="item.banner_image_url" alt="Profile Cover Photo" class="img-fluid card-img-top"> -->
                                <div class="asset-image">
                                    <img class="" [src]="item.banner_image_url" style="object-fit: contain; max-height: 100%; border-radius: 5px;" alt="Card image cap">
                                </div>
                                <div class="card-body" style="height: 300px;">
                                    <div class="profile-image-wrapper">
                                        <div class="profile-image">
                                            <div class="avatar"><img [src]="item.image_url" alt="Profile Picture"></div>
                                        </div>
                                    </div>
                                    <h3 class="text-truncate">{{item.name}}</h3>
                                    <h6 class="text-muted text-truncate">{{ item.description }}</h6>
                                    <div class="badge badge-light-primary profile-badge">Pro Level</div>
                                    <hr class="mb-2">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div>
                                            <h6 class="text-muted font-weight-bolder">Followers</h6>
                                            <h3 class="mb-0">10.3k</h3>
                                        </div>
                                        <div>
                                            <h6 class="text-muted font-weight-bolder">Projects</h6>
                                            <h3 class="mb-0">156</h3>
                                        </div>
                                        <div>
                                            <h6 class="text-muted font-weight-bolder">Rank</h6>
                                            <h3 class="mb-0">23</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ngu-tile>

                        <button NguCarouselPrev class="btn-sm btn-icon btn-outline-primary rounded-circle leftRs"
                            style="z-index: 999; " [style.opacity]="myCarousel.isFirst ? 0.5:1"><span
                                [data-feather]="'arrow-left'"></span></button>
                        <button NguCarouselNext class="btn-sm btn-icon btn-outline-primary rounded-circle rightRs"
                            [style.opacity]="myCarousel.isLast ? 0.5:1"><span
                                [data-feather]="'arrow-right'"></span></button>
                        <ul class="myPoint" NguCarouselPoint>
                            <li *ngFor="let j1 of myCarousel.pointNumbers; let j2 = index"
                                [class.active]="j1==myCarousel.activePoint" (click)="myCarousel.moveTo(j1)"
                                [style.background]="'url(' + carouselTileItems[j1] + ')'">
                            </li>
                        </ul>
                    </ngu-carousel>
                </div>
            </div>
        </section>
        <!-- NG-Carousel End -->
        <!-- How It Works -->
        <!-- Modern Vertical Wizard -->
        <div class="row justify-content-md-center">
            <div class="col-md-auto">
                <div class="section-title">How It Works</div>
            </div>
        </div>
        <div class="row justify-content-md-center mt-3">
            <section class="modern-vertical-wizard col-md-10 col-sm-11">
                <div id="stepper4" class="bs-stepper vertical wizard-modern modern-vertical-wizard-example">
                <div class="bs-stepper-header">
                    <div class="step" data-target="#account-details-vertical-modern">
                    <button type="button" class="step-trigger">
                        <span class="bs-stepper-box">
                        <i data-feather="file-text" class="font-medium-3"></i>
                        </span>
                        <span class="bs-stepper-label">
                        <span class="bs-stepper-title">Setup Wallet</span>
                        <span class="bs-stepper-subtitle">Setup your wallet</span>
                        </span>
                    </button>
                    </div>
                    <div class="step" data-target="#personal-info-vertical-modern">
                    <button type="button" class="step-trigger">
                        <span class="bs-stepper-box">
                        <i data-feather="user" class="font-medium-3"></i>
                        </span>
                        <span class="bs-stepper-label">
                        <span class="bs-stepper-title">Create Collection</span>
                        <span class="bs-stepper-subtitle">Setup your collection</span>
                        </span>
                    </button>
                    </div>
                    <div class="step" data-target="#address-step-vertical-modern">
                    <button type="button" class="step-trigger">
                        <span class="bs-stepper-box">
                        <i data-feather="map-pin" class="font-medium-3"></i>
                        </span>
                        <span class="bs-stepper-label">
                        <span class="bs-stepper-title">Mint NFTs</span>
                        <span class="bs-stepper-subtitle">Upload & issue your works</span>
                        </span>
                    </button>
                    </div>
                    <div class="step" data-target="#social-links-vertical-modern">
                    <button type="button" class="step-trigger">
                        <span class="bs-stepper-box">
                        <i data-feather="link" class="font-medium-3"></i>
                        </span>
                        <span class="bs-stepper-label">
                        <span class="bs-stepper-title">List For Sale</span>
                        <span class="bs-stepper-subtitle">List them for sale</span>
                        </span>
                    </button>
                    </div>
                </div>
                <div class="bs-stepper-content">
                    <div id="account-details-vertical-modern" class="content">
                    <div class="content-header">
                        <h5 class="mb-0">Account Details</h5>
                        <small class="text-muted">Enter Your Account Details.</small>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6">
                        <label class="form-label" for="vertical-modern-username">Username</label>
                        <input type="text" id="vertical-modern-username" class="form-control" placeholder="johndoe" />
                        </div>
                        <div class="form-group col-md-6">
                        <label class="form-label" for="vertical-modern-email">Email</label>
                        <input
                            type="email"
                            id="vertical-modern-email"
                            class="form-control"
                            placeholder="john.doe@email.com"
                            aria-label="john.doe"
                        />
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group form-password-toggle col-md-6">
                        <label class="form-label" for="vertical-modern-password">Password</label>
                        <input
                            type="password"
                            id="vertical-modern-password"
                            class="form-control"
                            placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                        />
                        </div>
                        <div class="form-group form-password-toggle col-md-6">
                        <label class="form-label" for="vertical-modern-confirm-password">Confirm Password</label>
                        <input
                            type="password"
                            id="vertical-modern-confirm-password"
                            class="form-control"
                            placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                        />
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <button class="btn btn-outline-secondary btn-prev" disabled rippleEffect>
                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                        <span class="align-middle d-sm-inline-block d-none">Previous</span>
                        </button>
                        <button class="btn btn-primary btn-next" (click)="modernVerticalNext()" rippleEffect>
                        <span class="align-middle d-sm-inline-block d-none">Next</span>
                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                        </button>
                    </div>
                    </div>
                    <div id="personal-info-vertical-modern" class="content">
                    <div class="content-header">
                        <h5 class="mb-0">Personal Info</h5>
                        <small>Enter Your Personal Info.</small>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6">
                        <label class="form-label" for="vertical-modern-first-name">First Name</label>
                        <input type="text" id="vertical-modern-first-name" class="form-control" placeholder="John" />
                        </div>
                        <div class="form-group col-md-6">
                        <label class="form-label" for="vertical-modern-last-name">Last Name</label>
                        <input type="text" id="vertical-modern-last-name" class="form-control" placeholder="Doe" />
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <button class="btn btn-primary btn-prev" (click)="modernVerticalPrevious()" rippleEffect>
                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                        <span class="align-middle d-sm-inline-block d-none">Previous</span>
                        </button>
                        <button class="btn btn-primary btn-next" (click)="modernVerticalNext()" rippleEffect>
                        <span class="align-middle d-sm-inline-block d-none">Next</span>
                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                        </button>
                    </div>
                    </div>
                    <div id="address-step-vertical-modern" class="content">
                    <div class="content-header">
                        <h5 class="mb-0">Address</h5>
                        <small>Enter Your Address.</small>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6">
                        <label class="form-label" for="vertical-modern-address">Address</label>
                        <input
                            type="text"
                            id="vertical-modern-address"
                            class="form-control"
                            placeholder="98  Borough bridge Road, Birmingham"
                        />
                        </div>
                        <div class="form-group col-md-6">
                        <label class="form-label" for="vertical-modern-landmark">Landmark</label>
                        <input type="text" id="vertical-modern-landmark" class="form-control" placeholder="Borough bridge" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6">
                        <label class="form-label" for="pincode4">Pincode</label>
                        <input type="text" id="pincode4" class="form-control" placeholder="658921" />
                        </div>
                        <div class="form-group col-md-6">
                        <label class="form-label" for="city4">City</label>
                        <input type="text" id="city4" class="form-control" placeholder="Birmingham" />
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <button class="btn btn-primary btn-prev" (click)="modernVerticalPrevious()" rippleEffect>
                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                        <span class="align-middle d-sm-inline-block d-none">Previous</span>
                        </button>
                        <button class="btn btn-primary btn-next" (click)="modernVerticalNext()" rippleEffect>
                        <span class="align-middle d-sm-inline-block d-none">Next</span>
                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                        </button>
                    </div>
                    </div>
                    <div id="social-links-vertical-modern" class="content">
                    <div class="content-header">
                        <h5 class="mb-0">Social Links</h5>
                        <small>Enter Your Social Links.</small>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6">
                        <label class="form-label" for="vertical-modern-twitter">Twitter</label>
                        <input
                            type="text"
                            id="vertical-modern-twitter"
                            class="form-control"
                            placeholder="https://twitter.com/abc"
                        />
                        </div>
                        <div class="form-group col-md-6">
                        <label class="form-label" for="vertical-modern-facebook">Facebook</label>
                        <input
                            type="text"
                            id="vertical-modern-facebook"
                            class="form-control"
                            placeholder="https://facebook.com/abc"
                        />
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6">
                        <label class="form-label" for="vertical-modern-google">Google+</label>
                        <input
                            type="text"
                            id="vertical-modern-google"
                            class="form-control"
                            placeholder="https://plus.google.com/abc"
                        />
                        </div>
                        <div class="form-group col-md-6">
                        <label class="form-label" for="vertical-modern-linkedin">Linkedin</label>
                        <input
                            type="text"
                            id="vertical-modern-linkedin"
                            class="form-control"
                            placeholder="https://linkedin.com/abc"
                        />
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <button class="btn btn-primary btn-prev" (click)="modernVerticalPrevious()" rippleEffect>
                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                        <span class="align-middle d-sm-inline-block d-none">Previous</span>
                        </button>
                        <button class="btn btn-success btn-submit" rippleEffect>Submit</button>
                    </div>
                    </div>
                </div>
                </div>
            </section>
        </div>
      <!-- /Modern Vertical Wizard -->
        <!-- How It Works End -->
        <!-- ngb-carousel component -->
        <!-- <ngb-carousel *ngIf="carouselImages">
            <ng-template ngbSlide>
                <div class="row">
                    <div class="col-md-6 card">
                        <div class="card-body">
                            <h4 class="card-title">Card title</h4>
                            <h6 class="card-subtitle text-muted">Support card subtitle</h6><img
                                src="assets/images/slider/01.jpg" alt="Card image cap" class="img-fluid my-2">
                            <p class="card-text">Bear claw sesame snaps gummies chocolate.</p><a
                                href="javascript:void(0)" class="card-link pink">Card link</a><a
                                href="javascript:void(0)" class="card-link pink">Another link</a>
                        </div>
                    </div>
                    <div class="col-md-6 card">
                        <div class="card-body">
                            <h4 class="card-title">Card title</h4>
                            <h6 class="card-subtitle text-muted">Support card subtitle</h6><img
                                src="assets/images/slider/02.jpg" alt="Card image cap" class="img-fluid my-2">
                            <p class="card-text">Bear claw sesame snaps gummies chocolate.</p><a
                                href="javascript:void(0)" class="card-link pink">Card link</a><a
                                href="javascript:void(0)" class="card-link pink">Another link</a>
                        </div>
                    </div>
                </div>
            <div class="carousel-caption"></div>
            </ng-template>
            <ng-template ngbSlide>
                <div class="row">
                    <div class="col-md-6 card">
                        <div class="card-body">
                            <h4 class="card-title">Card title</h4>
                            <h6 class="card-subtitle text-muted">Support card subtitle</h6><img
                                src="assets/images/slider/02.jpg" alt="Card image cap" class="img-fluid my-2">
                            <p class="card-text">Bear claw sesame snaps gummies chocolate.</p><a
                                href="javascript:void(0)" class="card-link pink">Card link</a><a
                                href="javascript:void(0)" class="card-link pink">Another link</a>
                        </div>
                    </div>
                    <div class="col-md-6 card">
                        <div class="card-body">
                            <h4 class="card-title">Card title</h4>
                            <h6 class="card-subtitle text-muted">Support card subtitle</h6><img
                                src="assets/images/slider/03.jpg" alt="Card image cap" class="img-fluid my-2">
                            <p class="card-text">Bear claw sesame snaps gummies chocolate.</p><a
                                href="javascript:void(0)" class="card-link pink">Card link</a><a
                                href="javascript:void(0)" class="card-link pink">Another link</a>
                        </div>
                    </div>
                </div>
            <div class="carousel-caption"></div>
            </ng-template>
            <ng-template ngbSlide>
                <div class="row">
                    <div class="col-md-6 card">
                        <div class="card-body">
                            <h4 class="card-title">Card title</h4>
                            <h6 class="card-subtitle text-muted">Support card subtitle</h6><img
                                src="assets/images/slider/03.jpg" alt="Card image cap" class="img-fluid my-2">
                            <p class="card-text">Bear claw sesame snaps gummies chocolate.</p><a
                                href="javascript:void(0)" class="card-link pink">Card link</a><a
                                href="javascript:void(0)" class="card-link pink">Another link</a>
                        </div>
                    </div>
                    <div class="col-md-6 card">
                        <div class="card-body">
                            <h4 class="card-title">Card title</h4>
                            <h6 class="card-subtitle text-muted">Support card subtitle</h6><img
                                src="assets/images/slider/04.jpg" alt="Card image cap" class="img-fluid my-2">
                            <p class="card-text">Bear claw sesame snaps gummies chocolate.</p><a
                                href="javascript:void(0)" class="card-link pink">Card link</a><a
                                href="javascript:void(0)" class="card-link pink">Another link</a>
                        </div>
                    </div>
                </div>
            <div class="carousel-caption"></div>
            </ng-template>
        </ngb-carousel> -->
        <!-- ngb-carousel component -->
        <!-- Carousel Test End -->
    </div>
</div>