<ng-container>
  <!-- Congratulations Card -->
  <div class="row justify-content-center">
    <div class="col-12 col-md-6 col-lg-7 justify-content-center align-items-center">
      <div class="card card-congratulations" *ngIf="direction !== undefined">
        <div class="card-body text-center">
          <img src="assets/images/elements/decore-left.png" class="congratulations-img-left" alt="card-img-left" />
          <img src="assets/images/elements/decore-right.png" class="congratulations-img-right" alt="card-img-right" />
          <div class="avatar avatar-xl bg-primary shadow">
            <div class="avatar-content">
              <!-- <i data-feather="award" class="font-large-1"></i> -->
              <img [src]="getImage(direction)" />
            </div>
          </div>
          <div class="text-center">
            <!-- <h1 class="mb-1 text-white">Congratulations {{ direction }},</h1>
            <p class="card-text m-auto w-75">
              Your currenct balance: {{ balance }} ETH
            </p> -->
            <h2 class="mb-1 text-white">
              Your Current Balance: {{ balance }} ETH
            </h2>
            <p class="card-text m-auto w-75">Connected Wallet Address: {{ direction }},</p>
          </div>
        </div>
      </div>
      <mat-card class="account" *ngIf="direction == undefined">
        <div style="text-align:center">
          <section class="header">
            <h3> Click connect account to connect the wallet provider </h3>
            <button mat-raised-button color="accent" class="big-round-shape-button" (click)="connectAccount()"> Connect Account</button>
            &nbsp;
          </section>
        </div>
      </mat-card>
    </div>
  </div>
  <!--/ Congratulations Card -->
</ng-container>