<!-- Ecommerce Item -->
<div class="asset-image text-center" style="height: 300px">
  <img class="asset-image" [src]="product.image_url" alt="img-placeholder" style="object-fit: contain; width: auto; height: auto; max-width: 100%; max-height: 100%;"/>
</div>
<div class="card-body">
  <div class="item-wrapper">
    <div class="item-rating">
      <div class="rating rating-sm">
        <ngb-rating [rate]="product.rating" [readonly]="true" [max]="5" class="outline-none">
          <ng-template let-fill="fill" let-index="index"><span class="fa fa-star-o mr-25"
              [class.fa-star]="fill === 100"></span></ng-template>
        </ngb-rating>
      </div>
    </div>
    <div class="item-cost">
      <h6 class="item-price">ETH {{ product.top_bid? product.top_bid : '0' }}</h6>
    </div>
  </div>
  <div class="d-flex justify-content-between align-items-center mb-1">
    <div class="media">
      <div class="avatar mr-1">
        <img [src]="product.owner.profile_img_url" alt="Avatar" width="42" height="42">
      </div>
      <div class="media-body">
        <h5 class="mb-0">{{ product.owner.user? product.owner.user.username : 'empty' }}</h5>
        <small class="text-muted"></small>
      </div>
    </div>
    <div class="badge badge-pill badge-light-primary">Design</div>
  </div>
  <h6 class="item-name">
    <!-- <a class="text-body" [routerLink]="'/apps/e-commerce/details/' + product.id"> {{ product.name }}</a> -->
    <a class="text-body" [routerLink]="'/details/' + product.id"> {{ product.name }}</a>
    <span class="card-text item-company">By <a href="javascript:void(0)"
        class="company-name">{{ product.owner.user? product.owner.user.username : 'empty' }}</a></span>
  </h6>
  <p class="card-text item-description">
    {{ product.description }}
  </p>
</div>
<div class="item-options text-center">
  <div class="item-wrapper">
    <div class="item-cost">
      <h4 class="item-price">{{ product.price }}</h4>
      <p class="card-text shipping" *ngIf="product.hasFreeShipping">
        <span class="badge badge-pill badge-light-success">Free Shipping</span>
      </p>
    </div>
  </div>
  <a *ngIf="isWishlistOpen === true" href="javascript:void(0)" class="btn btn-light btn-wishlist"
    (click)="toggleWishlist(product)" rippleEffect>
    <i data-feather="x"></i>
    <span>Remove</span>
  </a>
  <a *ngIf="isWishlistOpen !== true" href="javascript:void(0)" class="btn btn-light btn-wishlist"
    (click)="toggleWishlist(product)" rippleEffect>
    <i class="fa mr-25" [ngClass]="product.isInWishlist === true ? 'fa-heart text-danger' : 'fa-heart-o'"></i>
    <span>Wishlist</span>
  </a>
  <a *ngIf="product.isInCart !== true" href="javascript:void(0)" class="btn btn-primary btn-cart"
    (click)="addToCart(product)" rippleEffect>
    <i data-feather="shopping-cart" class="mr-25"></i>
    <span class="add-to-cart">Add to cart</span>
  </a>
  <a *ngIf="product.isInCart === true" routerLink="/apps/e-commerce/checkout" class="btn btn-primary btn-cart"
    rippleEffect>
    <i data-feather="shopping-cart" class="mr-25"></i>
    <span class="add-to-cart">View in cart</span>
  </a>
</div>
<!--/ Ecommerce Item -->