import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NguCarouselModule } from '@ngu/carousel';
import { AppMaterialModule } from 'app/app-material.module';

import { CoreCommonModule } from '@core/common.module';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { FileUploadModule } from "ng2-file-upload";

import { BreadcrumbModule } from '../../layout/components/content-header/breadcrumb/breadcrumb.module';
import { MarketplaceComponent } from './marketplace/marketplace.component';
import { MintNftComponent } from './mint-nft/mint-nft.component';
import { AboutComponent } from './about/about.component';
import { AccountComponent } from './account/account.component';
import { MyNftComponent } from './my-nft/my-nft.component';
import { NftHomeComponent } from './nft-home/nft-home.component';
import { EcommerceModule } from './ecommerce/ecommerce.module';
import { ContractService } from "services/contract/contract.service";

const routes = [
  {
    path: 'account',
    component: AccountComponent,
    data: { animation: 'account' }
  },
  {
    path: 'nft-home',
    component: NftHomeComponent,
    data: { animation: 'nft-home' }
  },
  // {
  //   path: 'marketplace',
  //   component: MarketplaceComponent,
  //   data: { animation: 'marketplace' }
  // },
  {
    path: 'mint-nft',
    component: MintNftComponent,
    data: { animation: 'mint-nft' }
  }
];

@NgModule({
  declarations: [AccountComponent, MarketplaceComponent, MintNftComponent, AboutComponent, MyNftComponent, NftHomeComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    // external dep
    AppMaterialModule, NgbModule, FileUploadModule,
    // vuexy module
    CoreCommonModule, ContentHeaderModule, BreadcrumbModule, NguCarouselModule, CoreDirectivesModule, CardSnippetModule, EcommerceModule,
  ],
  exports: [
    AccountComponent, MarketplaceComponent, MintNftComponent, AboutComponent, MyNftComponent
  ],
  providers: [
    ContractService
  ]
})
export class NftMarketplaceModule { }
