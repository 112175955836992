import { Component, OnInit } from "@angular/core";
import { ContractService } from "services/contract/contract.service";
// import { ThreeBox } from "services/3box.service";
import { DomSanitizer } from "@angular/platform-browser";
import { Identicon } from "services/identicon";
import { Md5 } from "ts-md5/dist/md5";

@Component({
  selector: "app-account",
  templateUrl: "./account.component.html",
  styleUrls: ["./account.component.scss"],
})
export class AccountComponent implements OnInit {
  direction: string;
  balance: string;
  profile;
  url;
  identiConData;

  constructor(
    private contract: ContractService,
    private sanitizer: DomSanitizer,
    // private threebox: ThreeBox
  ) {
    this.contract
      .connectAccount()
      .then((value: any) => {
        console.log(value);
        this.direction = value;
        this.getDetails(this.direction);
       /* this.profile = this.threebox.getProfile(this.direction).then((response) => {
            console.log(response);
            this.profile = response;
            this.url = this.profile.image[0].contentUrl["/"];
          }); */
        this.getImage(this.direction);
        console.log('아이덴티콘...', this.identiConData);
      })
      .catch((error: any) => {
        this.contract.failure(
          "Could't get the account data, please check if metamask is running correctly and refresh the page"
        );
      });
  }

  ngOnInit() {

  }
  
  getImage(account) {
    console.log(account);
    this.identiConData = this.sanitizer.bypassSecurityTrustResourceUrl(
      "data:image/svg+xml; utf8," +
      encodeURI(
        new Identicon(Md5.hashAsciiStr(String(account)), {
          size: 32,
          format: "svg",
        }).toString(true)
      )
    );
    return this.identiConData;
  }

  navigateTo() {
    window.open("https://metamask.io/");
  }

  connectAccount() {
    this.contract
      .connectAccount()
      .then((value: any) => {
        console.log(value);
        this.direction = value;
        this.getDetails(this.direction);
      })
      .catch((error: any) => {
        this.contract.failure(
          "Could't get the account data, please check if metamask is running correctly and refresh the page"
        );
      });
  }

  getDetails(account) {
    this.contract
      .accountInfo(account)
      .then((value: any) => {
        console.log(value);
        this.balance = value;
      })
      .catch((error: any) => {
        this.contract.failure(
          "Could't get the account data, please check if metamask is running correctly and refresh the page"
        );
      });
  }
}
