<!-- Ecommerce Sidebar Starts -->
<div class="row mt-1">
  <div class="col-sm-12">
    <h6 class="filter-heading d-none d-lg-block">Filters</h6>
  </div>
</div>
<div class="card">
  <div class="card-body">
    <!-- Price Filter starts -->
    <div class="multi-range-price">
      <h6 class="filter-title mt-0">Multi Range</h6>
      <ul class="list-unstyled price-range" id="price-range">
        <li>
          <div class="custom-control custom-radio">
            <input type="radio" id="priceAll" name="price-range" class="custom-control-input" checked />
            <label class="custom-control-label" for="priceAll">All</label>
          </div>
        </li>
        <li>
          <div class="custom-control custom-radio">
            <input type="radio" id="priceRange1" name="price-range" class="custom-control-input" />
            <label class="custom-control-label" for="priceRange1">&lt;=10 ETH</label>
          </div>
        </li>
        <li>
          <div class="custom-control custom-radio">
            <input type="radio" id="priceRange2" name="price-range" class="custom-control-input" />
            <label class="custom-control-label" for="priceRange2">10 - 100 ETH</label>
          </div>
        </li>
        <li>
          <div class="custom-control custom-radio">
            <input type="radio" id="priceARange3" name="price-range" class="custom-control-input" />
            <label class="custom-control-label" for="priceARange3">100 - 500 ETH</label>
          </div>
        </li>
        <li>
          <div class="custom-control custom-radio">
            <input type="radio" id="priceRange4" name="price-range" class="custom-control-input" />
            <label class="custom-control-label" for="priceRange4">&gt;= 500 ETH</label>
          </div>
        </li>
      </ul>
    </div>
    <!-- Price Filter ends -->

    <!-- Price Slider starts -->
    <div class="price-slider">
      <h6 class="filter-title">Bid Range</h6>
      <div class="price-slider">
        <nouislider [connect]="true" [min]="0" [max]="10" [step]="5" [(ngModel)]="sliderPriceValue"></nouislider>
      </div>
    </div>
    <!-- Price Range ends -->

    <!-- Categories Starts -->
    <div id="product-categories">
      <h6 class="filter-title">Categories</h6>
      <ul class="list-unstyled categories-list">
        <li>
          <div class="custom-control custom-radio">
            <input type="radio" id="category1" name="category-filter" class="custom-control-input" checked />
            <label class="custom-control-label" for="category1">Design</label>
          </div>
        </li>
        <li>
          <div class="custom-control custom-radio">
            <input type="radio" id="category2" name="category-filter" class="custom-control-input" />
            <label class="custom-control-label" for="category2">Art</label>
          </div>
        </li>
        <li>
          <div class="custom-control custom-radio">
            <input type="radio" id="category3" name="category-filter" class="custom-control-input" />
            <label class="custom-control-label" for="category3">Music</label>
          </div>
        </li>
        <li>
          <div class="custom-control custom-radio">
            <input type="radio" id="category4" name="category-filter" class="custom-control-input" />
            <label class="custom-control-label" for="category4">Domain Name</label>
          </div>
        </li>
        <li>
          <div class="custom-control custom-radio">
            <input type="radio" id="category5" name="category-filter" class="custom-control-input" />
            <label class="custom-control-label" for="category5">Virtual World</label>
          </div>
        </li>
        <li>
          <div class="custom-control custom-radio">
            <input type="radio" id="category6" name="category-filter" class="custom-control-input" />
            <label class="custom-control-label" for="category6">Trading Cards</label>
          </div>
        </li>
        <li>
          <div class="custom-control custom-radio">
            <input type="radio" id="category7" name="category-filter" class="custom-control-input" />
            <label class="custom-control-label" for="category7">Collectibles</label>
          </div>
        </li>
      </ul>
    </div>
    <!-- Categories Ends -->

    <!-- Brands starts -->
    <div class="brands">
      <h6 class="filter-title">Coins</h6>
      <ul class="list-unstyled brand-list">
        <li>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="productBrand1" />
            <label class="custom-control-label" for="productBrand1">ETH</label>
          </div>
          <span>746</span>
        </li>
        <li>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="productBrand2" checked />
            <label class="custom-control-label" for="productBrand2">WETH</label>
          </div>
          <span>633</span>
        </li>
        <li>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="productBrand3" />
            <label class="custom-control-label" for="productBrand3">0xBTC</label>
          </div>
          <span>591</span>
        </li>
        <li>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="productBrand4" />
            <label class="custom-control-label" for="productBrand4">1337</label>
          </div>
          <span>530</span>
        </li>
        <li>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="productBrand5" checked />
            <label class="custom-control-label" for="productBrand5">1MT</label>
          </div>
          <span>442</span>
        </li>
      </ul>
    </div>
    <!-- Brand ends -->

    <!-- Rating starts -->
    <div id="ratings">
      <h6 class="filter-title">Ratings</h6>
      <div class="ratings-list">
        <a href="javascript:void(0)">
          <span class="rating rating-sm">
            <ngb-rating [rate]="4" [readonly]="true" [max]="5" class="outline-none">
              <ng-template let-fill="fill" let-index="index"
                ><span class="fa fa-star-o mr-25" [class.fa-star]="fill === 100"></span
              ></ng-template>
            </ngb-rating>
          </span>
          & up
        </a>
        <div class="stars-received">160</div>
      </div>
      <div class="ratings-list">
        <a href="javascript:void(0)">
          <span class="rating rating-sm">
            <ngb-rating [rate]="3" [readonly]="true" [max]="5" class="outline-none">
              <ng-template let-fill="fill" let-index="index"
                ><span class="fa fa-star-o mr-25" [class.fa-star]="fill === 100"></span
              ></ng-template>
            </ngb-rating>
          </span>
          & up
        </a>
        <div class="stars-received">176</div>
      </div>
      <div class="ratings-list">
        <a href="javascript:void(0)">
          <span class="rating rating-sm">
            <ngb-rating [rate]="2" [readonly]="true" [max]="5" class="outline-none">
              <ng-template let-fill="fill" let-index="index"
                ><span class="fa fa-star-o mr-25" [class.fa-star]="fill === 100"></span
              ></ng-template>
            </ngb-rating>
          </span>
          & up
        </a>
        <div class="stars-received">291</div>
      </div>
      <div class="ratings-list">
        <a href="javascript:void(0)">
          <span class="rating rating-sm">
            <ngb-rating [rate]="1" [readonly]="true" [max]="5" class="outline-none">
              <ng-template let-fill="fill" let-index="index"
                ><span class="fa fa-star-o mr-25" [class.fa-star]="fill === 100"></span
              ></ng-template>
            </ngb-rating>
          </span>
          & up
        </a>
        <div class="stars-received">190</div>
      </div>
    </div>
    <!-- Rating ends -->

    <!-- Clear Filters Starts -->
    <div id="clear-filters">
      <button type="button" class="btn btn-block btn-primary" rippleEffect>Clear All Filters</button>
    </div>
    <!-- Clear Filters Ends -->
  </div>
</div>
<!-- Ecommerce Sidebar Ends -->
