export const locale = {
  lang: 'en',
  data: {
    MENU: {
      HOME: 'Home',
      SAMPLE: 'Sample',
      MARKETPLACE: 'Marketplace',
      MINT_NFT: 'Mint NFT',
    }
  }
}
