<div class="content-wrapper">
  <app-content-header [contentHeader]="contentHeader"></app-content-header>
  <!-- Sidebar filters  -->
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <core-sidebar
        class="sidebar-shop"
        id="ecommerce-sidebar-toggler"
        name="ecommerce-sidebar"
        collapsibleSidebar="bs-gt-lg"
        overlayClass="body-content-overlay"
      >
        <ecommerce-sidebar></ecommerce-sidebar>
      </core-sidebar>
    </div>
  </div>
  <!--/ Sidebar filters -->

  <div class="content-detached content-right">
    <div class="content-body">
      <!-- E-commerce Head Section Starts -->
      <section id="ecommerce-header">
        <div class="row">
          <div class="col-sm-12">
            <div class="ecommerce-header-items">
              <div class="result-toggler">
                <button
                  class="navbar-toggler shop-sidebar-toggler"
                  type="button"
                  data-toggle="collapse"
                  (click)="toggleSidebar('ecommerce-sidebar')"
                >
                  <span class="navbar-toggler-icon d-block d-lg-none"><i data-feather="menu"></i></span>
                </button>
                <div class="search-results">{{ (products | filter: searchText:'name').length }} results found</div>
              </div>
              <div class="view-options d-flex">
                <div class="btn-group dropdown-sort" ngbDropdown>
                  <button
                    ngbDropdownToggle
                    type="button"
                    class="btn btn-outline-primary mr-1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    rippleEffect
                  >
                    <span class="active-sorting">Featured</span>
                  </button>
                  <div ngbDropdownMenu>
                    <a ngbDropdownItem href="javascript:void(0);" (click)="sortProduct('featured')">Featured</a>
                    <a ngbDropdownItem href="javascript:void(0);" (click)="sortProduct('price-asc')">Lowest</a>
                    <a ngbDropdownItem href="javascript:void(0);" (click)="sortProduct('price-desc')">Highest</a>
                  </div>
                </div>
                <div class="btn-group btn-group-toggle" data-toggle="buttons">
                  <label
                    class="btn btn-icon btn-outline-primary view-btn grid-view-btn"
                    [class.active]="gridViewRef === true"
                  >
                    <input type="radio" name="radio_options" id="radio_option1" (click)="gridView()" />
                    <i data-feather="grid" class="font-medium-3"></i>
                  </label>
                  <label
                    class="btn btn-icon btn-outline-primary view-btn list-view-btn"
                    [class.active]="gridViewRef !== true"
                  >
                    <input type="radio" name="radio_options" id="radio_option2" (click)="listView()" />
                    <i data-feather="list" class="font-medium-3"></i>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="ecommerce-searchbar" class="ecommerce-searchbar">
        <div class="row mt-1">
          <div class="col-sm-12">
            <div class="input-group input-group-merge">
              <input
                type="text"
                class="form-control search-product"
                id="shop-search"
                placeholder="Search Product"
                aria-label="Search..."
                aria-describedby="shop-search"
                [(ngModel)]="searchText"
              />
              <div class="input-group-append">
                <span class="input-group-text"><i data-feather="search" class="text-muted"></i></span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- E-commerce Head Section Starts -->

      <!-- E-commerce Products Starts -->
      <section id="ecommerce-products" [ngClass]="gridViewRef === true ? 'grid-view' : 'list-view'">
        <app-ecommerce-item
          class="card ecommerce-card"
          [product]="product"
          *ngFor="
            let product of products
              | filter: searchText:'name'
              | slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize
          "
        ></app-ecommerce-item>
      </section>
      <!-- E-commerce Products Ends -->

      <!-- E-commerce Pagination Starts -->
      <section id="ecommerce-pagination">
        <div class="row">
          <div class="col-sm-12">
            <div class="d-flex justify-content-center">
              <ngb-pagination
                [collectionSize]="(products | filter: searchText:'name').length"
                [(page)]="page"
                [maxSize]="5"
                [rotate]="true"
                [ellipses]="false"
                [boundaryLinks]="true"
              >
                <!-- Custom pagination icons -->
                <ng-template ngbPaginationPrevious
                  ><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"> </span>
                </ng-template>
                <ng-template ngbPaginationFirst
                  ><span [data-feather]="'chevrons-left'" [class]="'font-weight-bolder'"></span>
                </ng-template>
                <ng-template ngbPaginationNext
                  ><span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span
                ></ng-template>
                <ng-template ngbPaginationLast
                  ><span [data-feather]="'chevrons-right'" [class]="'font-weight-bolder'"></span>
                </ng-template>
              </ngb-pagination>
            </div>
          </div>
        </div>
      </section>
      <!-- E-commerce Pagination Ends -->
    </div>
  </div>
</div>
