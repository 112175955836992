<div class="content-wrapper">
  <app-content-header [contentHeader]="contentHeader"></app-content-header>
  <div class="content-body">
    <!-- app e-commerce details start -->
    <section class="app-ecommerce-details">
      <div class="card">
        <!-- Product Details starts -->
        <div class="card-body">
          <div class="row my-2">
            <div class="col-12 col-md-5 d-flex align-items-center justify-content-center mb-2 mb-md-0">
              <div class="d-flex align-items-center justify-content-center">
                <!-- <img [src]="product.image" class="img-fluid product-img" alt="product image" /> -->
                <img [src]="product.image_url" class="img-fluid product-img" alt="product image" />
              </div>
            </div>
            <div class="col-12 col-md-7">
              <h4>{{ product.name }}</h4>
              <!-- <span class="card-text item-company"
                >By <a href="javascript:void(0)" class="company-name">{{ product.brand }}</a></span
              > -->
              <span class="card-text item-company"
                >By <a href="javascript:void(0)" class="company-name">{{ product.creator.user.username }} {{ product.creator.address}}</a></span
              >
              <div class="ecommerce-details-price d-flex flex-wrap mt-1">
                <!-- <h4 class="item-price mr-1">${{ product.price }}</h4> -->
                <h4 class="item-price mr-1">ETH {{ product.price }}</h4>
                <div class="rating rating-sm pl-1 border-left mb-1">
                  <ngb-rating [rate]="product.rating" [readonly]="true" [max]="5" class="outline-none">
                    <ng-template let-fill="fill" let-index="index"
                      ><span class="fa fa-star-o mr-25" [class.fa-star]="fill === 100"></span
                    ></ng-template>
                  </ngb-rating>
                </div>
              </div>
              <p class="card-text">Available - <span class="text-success">In stock</span></p>
              <p class="card-text">
                {{ product.description }}
              </p>
              <ul class="product-features list-unstyled">
                <li *ngIf="product.hasFreeShipping"><i data-feather="shopping-cart"></i> <span>Free Shipping</span></li>
                <li>
                  <i data-feather="dollar-sign"></i>
                  <span>EMI options available</span>
                </li>
              </ul>
              <hr />
              <div class="product-color-options">
                <h6>Colors</h6>
                <ul class="list-unstyled mb-0">
                  <li class="d-inline-block selected">
                    <div class="color-option b-primary">
                      <div class="filloption bg-primary"></div>
                    </div>
                  </li>
                  <li class="d-inline-block">
                    <div class="color-option b-success">
                      <div class="filloption bg-success"></div>
                    </div>
                  </li>
                  <li class="d-inline-block">
                    <div class="color-option b-danger">
                      <div class="filloption bg-danger"></div>
                    </div>
                  </li>
                  <li class="d-inline-block">
                    <div class="color-option b-warning">
                      <div class="filloption bg-warning"></div>
                    </div>
                  </li>
                  <li class="d-inline-block">
                    <div class="color-option b-info">
                      <div class="filloption bg-info"></div>
                    </div>
                  </li>
                </ul>
              </div>
              <hr />
              <div class="d-flex flex-column flex-sm-row pt-1">
                <a
                  *ngIf="product.isInCart !== true"
                  href="javascript:void(0)"
                  class="btn btn-primary btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                  (click)="addToCart(product)"
                >
                  <i data-feather="shopping-cart" class="mr-25"></i>
                  <span class="add-to-cart">Add to cart</span>
                </a>
                <a
                  *ngIf="product.isInCart === true"
                  routerLink="/apps/e-commerce/checkout"
                  class="btn btn-primary btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                  rippleEffect
                >
                  <i data-feather="shopping-cart" class="mr-25"></i>
                  <span class="add-to-cart">View in cart</span>
                </a>
                <a
                  href="javascript:void(0)"
                  (click)="toggleWishlist(product)"
                  class="btn btn-outline-secondary btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0"
                  rippleEffect
                >
                  <i
                    class="fa mr-50"
                    [ngClass]="product.isInWishlist === true ? 'fa-heart text-danger' : 'fa-heart-o'"
                  ></i>
                  <span>Wishlist</span>
                </a>
                <div class="btn-group dropdown-icon-wrapper btn-share" ngbDropdown>
                  <button
                    ngbDropdownToggle
                    type="button"
                    class="btn btn-icon hide-arrow btn-outline-secondary"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    rippleEffect
                  >
                    <i data-feather="share-2"></i>
                  </button>
                  <div class="dropdown-menu-right" ngbDropdownMenu>
                    <a ngbDropdownItem href="javascript:void(0)">
                      <i data-feather="facebook"></i>
                    </a>
                    <a ngbDropdownItem href="javascript:void(0)">
                      <i data-feather="twitter"></i>
                    </a>
                    <a ngbDropdownItem href="javascript:void(0)">
                      <i data-feather="youtube"></i>
                    </a>
                    <a ngbDropdownItem href="javascript:void(0)">
                      <i data-feather="instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Product Details ends -->

        <!-- Item features starts -->
        <div class="item-features">
          <div class="row text-center">
            <div class="col-12 col-md-4 mb-4 mb-md-0">
              <div class="w-75 mx-auto">
                <i data-feather="award"></i>
                <h4 class="mt-2 mb-1">100% Original</h4>
                <p class="card-text">Chocolate bar candy canes ice cream toffee. Croissant pie cookie halvah.</p>
              </div>
            </div>
            <div class="col-12 col-md-4 mb-4 mb-md-0">
              <div class="w-75 mx-auto">
                <i data-feather="clock"></i>
                <h4 class="mt-2 mb-1">10 Day Replacement</h4>
                <p class="card-text">Marshmallow biscuit donut dragée fruitcake. Jujubes wafer cupcake.</p>
              </div>
            </div>
            <div class="col-12 col-md-4 mb-4 mb-md-0">
              <div class="w-75 mx-auto">
                <i data-feather="shield"></i>
                <h4 class="mt-2 mb-1">1 Year Warranty</h4>
                <p class="card-text">Cotton candy gingerbread cake I love sugar plum I love sweet croissant.</p>
              </div>
            </div>
          </div>
        </div>
        <!-- Item features ends -->

        <!-- Related Products starts -->
        <div class="card-body">
          <div class="mt-4 mb-2 text-center">
            <h4>Related Products</h4>
            <p class="card-text">People also search for this items</p>
          </div>
          <div class="swiper-responsive-breakpoints swiper-container px-4 py-2" [swiper]="swiperResponsive">
            <div class="swiper-wrapper mb-2">
              <div class="swiper-slide" *ngFor="let relatedProduct of relatedProducts">
                <a href="javascript:void(0)">
                  <div class="item-heading">
                    <h5 class="text-truncate mb-0">{{ relatedProduct.name }}</h5>
                    <small class="text-body">by {{ relatedProduct.brand }}</small>
                  </div>
                  <div class="img-container mx-auto py-75">
                    <!-- <img [src]="relatedProduct.image" class="img-fluid" alt="image" /> -->
                    <img [src]="relatedProduct.image_url" class="img-fluid" alt="image" />
                  </div>
                  <div class="item-meta">
                    <div class="rating rating-sm">
                      <ngb-rating [rate]="relatedProduct.rating" [readonly]="true" [max]="5" class="outline-none">
                        <ng-template let-fill="fill" let-index="index"
                          ><span class="fa fa-star-o mr-25" [class.fa-star]="fill === 100"></span
                        ></ng-template>
                      </ngb-rating>
                    </div>
                    <p class="card-text text-primary mb-0">${{ relatedProduct.price }}</p>
                  </div>
                </a>
              </div>
            </div>
            <!-- Add Arrows -->
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </div>
        </div>
        <!-- Related Products ends -->
      </div>
    </section>
    <!-- app e-commerce details end -->
  </div>
</div>
