import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-nft',
  templateUrl: './my-nft.component.html',
  styleUrls: ['./my-nft.component.scss']
})
export class MyNftComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
