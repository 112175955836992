import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  {
    id: 'home',
    title: 'Home',
    translate: 'MENU.HOME',
    type: 'item',
    icon: 'home',
    url: 'nft-home'
  },
  {
    id: 'marketplace',
    title: 'Marketplace',
    translate: 'MENU.MARKETPLACE',
    type: 'item',
    icon: 'grid',
    url: 'marketplace'
  },
  {
    id: 'mint-nft',
    title: 'Mint NFT',
    translate: 'MENU.MINT_NFT',
    type: 'item',
    icon: 'package',
    url: 'mint-nft'
  },
  // {
  //   id: 'sample',
  //   title: 'Sample',
  //   translate: 'MENU.SAMPLE',
  //   type: 'item',
  //   icon: 'magic',
  //   url: 'sample'
  // },
]
