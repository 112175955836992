<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <!-- <span class="brand-logo"> -->
        <span class="">
          <!-- <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="48" /> -->
          <img src="../../../../assets/images/unilord/unilord.png" alt="brand-logo" height="48" />
        </span>
        <!-- <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2> -->
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->

    <!-- Toggle skin -->
    <li class="nav-item d-none d-lg-block">
      <!-- <a type="button" class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
        <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
      </a> -->
    </li>
    <!--/ Toggle skin -->
  </div>

  <ul class="nav navbar-nav align-items-center ml-auto">
    <!-- ? Language selection | Uncomment if needed-->
    <!-- <li ngbDropdown class="nav-item dropdown dropdown-language">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i
        ><span class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span></a
      >
      <div ngbDropdownMenu aria-labelledby="dropdown-flag">
        <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
          <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i> {{ languageOptions[lang].title }}
        </a>
      </div>
    </li> -->
    <!--/ Language selection -->

    <!-- Mainnet Select -->
    <div class="col text-right">
      <div ngbDropdown placement="bottom-left" class="d-inline-block">
        <button class="btn btn-outline-primary" id="dropdownBasic2" ngbDropdownToggle>Select Chain</button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic2" style="width: 300px">
          <div *ngFor="let chains of nftChains">
          <button ngbDropdownItem (click)="setChain(chains, $event)"><span class="avatar"
            ><img
              class="round"
              [src]="chains.src"
              [alt]="chains.name"
              height="30"
              width="30" />
            </span><span class="ml-1">{{chains.title}}</span></button>
          </div>
          <!-- <button ngbDropdownItem (click)="setChain('binance', $event)"><span class="avatar"
            ><img
              class="round"
              src="../../../../assets/images/blockchain/ethereum-eth.svg"
              alt="ethereum"
              height="30"
              width="30" />
            </span><span class="ml-1">Ethereum Mainnet</span></button>
          <button ngbDropdownItem><span class="avatar"
            ><img
              class="round"
              src="../../../../assets/images/blockchain/binance-logo.svg"
              alt="binance"
              height="30"
              width="30" />
            </span><span class="ml-1">Binance Mainnet</span></button>
          <button ngbDropdownItem><span class="avatar"
            ><img
              class="round"
              src="../../../../assets/images/blockchain/huobi-logo.svg"
              alt="huobi"
              height="30"
              width="30" />
            </span><span class="ml-1">Huobi HECO Mainnet</span></button>
          <button ngbDropdownItem><span class="avatar"
            ><img
              class="round"
              src="../../../../assets/images/blockchain/okex-logo.svg"
              alt="okex"
              height="30"
              width="30" />
            </span><span class="ml-1">OKEx Chain Mainnet</span></button>
          <button ngbDropdownItem><span class="avatar"
              ><img
              class="round"
              src="../../../../assets/images/blockchain/ethereum-logo.svg"
              alt="ethereum"
              height="30"
              width="30" />
            </span><span class="ml-1">Ethereum Testnet</span></button> -->
        </div>
      </div>
    </div>
    <!-- Mainnet Select End -->
    <!-- Wallet Connection-->
    <li ngbDropdown class="nav-item dropdown-user">
      <!-- <button class="email-favorite btn p-0" (click)="toggleStarred(); $event.stopPropagation()">
        <i
          class="fa"
          [ngClass]="{ 'fa-wallet text-warning': email.isStarred === true, 'fa-wallet-o': email.isStarred === false }"
        ></i>
      </button> -->
      <!-- <button mat-icon-button [routerLink]="['/account']"> -->
        <!-- <mat-icon>account_balance_wallet<span [ngClass]="{ 'avatar-status-online': currentWallet }"></span></mat-icon> -->
        <span class="avatar" [routerLink]="['/account']"
        ><img
          class="round"
          [src]="selectedChain.src"
          alt="avatar"
          height="40"
          width="40"><span [ngClass]="{ 'avatar-status-online': currentWallet }"></span
        ></span>
      <!-- </button> -->
    </li>
    <!-- Wallet Connection End-->
    <!-- User Dropdown -->
    <!-- <li ngbDropdown class="nav-item dropdown-user">
      <a
        class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div class="user-nav d-sm-flex d-none">
          <span class="user-name font-weight-bolder">John Doe</span><span class="user-status">Sample</span>
        </div>
        <span class="avatar"
          ><img
            class="round"
            src="../../../../assets/images/portrait/small/avatar-s-11.jpg"
            alt="avatar"
            height="40"
            width="40" /><span class="avatar-status-online"></span
        ></span>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
        <a ngbDropdownItem [routerLink]="['/']"><span [data-feather]="'user'" [class]="'mr-50'"></span> Profile</a
        ><a ngbDropdownItem [routerLink]="['/']"><span [data-feather]="'mail'" [class]="'mr-50'"></span> Inbox</a
        ><a ngbDropdownItem [routerLink]="['/']"><span [data-feather]="'check-square'" [class]="'mr-50'"></span> Task</a
        ><a ngbDropdownItem [routerLink]="['/']"
          ><span [data-feather]="'message-square'" [class]="'mr-50'"></span> Chats</a
        >
        <div class="dropdown-divider"></div>
        <a ngbDropdownItem [routerLink]="['/pages/authentication/login-v2']"
          ><span [data-feather]="'power'" [class]="'mr-50'"></span> Logout</a
        >
      </div>
    </li> -->
    <!--/ User Dropdown -->
  </ul>
</div>
