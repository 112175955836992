import { Component, OnInit } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import Stepper from 'bs-stepper';
import * as _ from 'lodash';

import { EcommerceService } from '../ecommerce/ecommerce.service'

@Component({
  selector: 'app-nft-home',
  templateUrl: './nft-home.component.html',
  styleUrls: ['./nft-home.component.scss']
})
export class NftHomeComponent implements OnInit {

  constructor(private _ecommerceService: EcommerceService) { }

  public contentHeader: object
  public carouselImages: object
  private modernVerticalWizardStepper: Stepper;

  public bundleProductLists: Array<any> = []
  public collectionProductLists: Array<any> = []
  
  carouselItems = [
    { 
      avatar: 'assets/images/portrait/small/avatar-s-1.jpg', 
      background: 'assets/images/banner/banner-9.jpg',
      artist: 'Ralph Lauren'
    },
    { 
      avatar: 'assets/images/portrait/small/avatar-s-2.jpg', 
      background: 'assets/images/banner/banner-8.jpg',
      artist: 'Christian Dior'
    },
    { 
      avatar: 'assets/images/portrait/small/avatar-s-3.jpg', 
      background: 'assets/images/banner/banner-7.jpg',
      artist: 'Yves Saint Laurent'
    },
    { 
      avatar: 'assets/images/portrait/small/avatar-s-4.jpg', 
      background: 'assets/images/banner/banner-6.jpg',
      artist: 'Coco Chanel'
    },
    { 
      avatar: 'assets/images/portrait/small/avatar-s-5.jpg', 
      background: 'assets/images/banner/banner-5.jpg',
      artist: 'Louis Vuitton'
    },
  ];
  mainItems: any[] = [...this.carouselItems];
  featuredSlides: any[] = [];
  collectionSlides: any[] = [...this.carouselItems];
  randomPickSlides: any[] = [];
  slideCurrent: number = 0;
  slidePrevious: number = 0;
  itemIndex: number = 0;

  public carouselTileItems: Array<any> = [0, 1, 2, 3, 4, 5];
  public carouselTiles = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: []
  };
  public carouselTile: NguCarouselConfig = {
    grid: { xs: 1, sm: 2, md: 3, lg: 3, xl:3, all: 0 },
    slide: 1,
    speed: 250,
    interval: {timing: 4000, initialDelay: 1000},
    point: {
      visible: true
    },
    load: 3,
    velocity: 0.2,
    touch: true,
    loop: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
    // grid: { xs: 3, sm: 3, md: 3, lg: 3, all: 0 },
    // load: 3,
    // slide : 1,
    // interval: {timing: 4000, initialDelay: 1000},
    // loop: true,
    // touch: true,
    // velocity: 0.2,
    // vertical : {
    //   enabled : true,
    //   height : 600
    // }
  };

  // public carouselTileLoad(data) {
  //   // // console.log(this.carouselTiles[j]);
  //   // const len = this.carouselTiles[tile].length;
  //   // if (len <= 30) {
  //   //   for (let i = len; i < len + 15; i++) {
  //   //     this.carouselTiles[tile].push(
  //   //       this.images[Math.floor(Math.random() * this.images.length)]
  //   //     );
  //   //   }
  //   // }

  //   this.carouselItems = [...this.carouselItems];
  // }

  // for infinity loop??
  // public carouselTileLoad(event) {

  //   // update slider index
  //   this.slideCurrent = event;
  //   let previous_index = this.slideCurrent === 0 ? 2 : this.slideCurrent - 1;
  //   let next_index = this.slideCurrent === 2 ? 0 : this.slideCurrent + 1;

  //   // update item index
  //   if (previous_index == this.slidePrevious) {
  //     console.log('slide up');
  //     this.itemIndex = this.findNextIndex(this.itemIndex);
  //   }
  //   if (next_index == this.slidePrevious) {
  //     console.log('slide down');
  //     this.itemIndex = this.findPreviousIndex(this.itemIndex);
  //   }

  //   // update slider with items
  //   this.slides[previous_index] = this.carouselItems[this.findPreviousIndex(this.itemIndex)];
  //   this.slides[this.slideCurrent] = this.carouselItems[this.itemIndex];
  //   this.slides[next_index] = this.carouselItems[this.findNextIndex(this.itemIndex)];

  //   // keep slidePrevious to determine if we slided up or down
  //   this.slidePrevious = this.slideCurrent;
  // }

  // private findNextIndex(index) {
  //   return this.itemIndex + 1 === this.carouselItems.length ? 0 : this.itemIndex + 1;
  // }

  // private findPreviousIndex(index) {
  //   return this.itemIndex - 1 === -1 ? this.carouselItems.length - 1 : this.itemIndex - 1;
  // }

  /**
   * Modern Vertical Wizard Stepper Next
   */
  modernVerticalNext() {
    this.modernVerticalWizardStepper.next();
  }
  /**
   * Modern Vertical Wizard Stepper Previous
   */
  modernVerticalPrevious() {
    this.modernVerticalWizardStepper.previous();
  }
  
  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.contentHeader = {
      headerTitle: 'Home',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: false,
          }
        ]
      }
    }

    this.carouselImages = [944, 1011, 984, 345].map((n) => `https://picsum.photos/id/${n}/900/500`);

    // this.carouselTileItems.forEach(el => {
    //   this.carouselTileLoad(el);
    // });

    this.modernVerticalWizardStepper = new Stepper(document.querySelector('#stepper4'), {
      linear: false,
      animation: true
    });

    // this._ecommerceService.getProducts()
    // .then(res => {
    //   console.log('상품 패치...', res)
    // })
    // .catch(error => {
    //   console.log('상품 패치 실패...', error)
    // })

    this._ecommerceService.getOpenSeaCollections()
    .then(res => {
      this.collectionProductLists = res.filter(el => el.banner_image_url && el.external_url != "http://trigner.com")
      this.collectionSlides = this.collectionProductLists
      this.randomPickSlides = _.sampleSize(this.collectionSlides, 4)
      console.log('OpenSea 컬렉션 패치...', this.collectionProductLists, this.randomPickSlides)
    })
    .catch(error => {
      console.log('OpenSea 컬렉션 패치 실패...', error)
    })

    this._ecommerceService.getOpenSeaBundles()
    .then(res => {
      this.bundleProductLists = res.filter(el => el.maker.user && el.maker.user.username)
      this.featuredSlides = this.bundleProductLists
      console.log('OpenSea Bundle 패치...', this.bundleProductLists)
    })
    .then(error => {

    })
  }
}
