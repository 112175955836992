<div class="content-wrapper">
    <div class="content-body">
        <!-- content-header component -->
        <app-content-header [contentHeader]="contentHeader"></app-content-header>
        <!-- Basic Alerts start -->
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Marketplace 🚀</h4>
                        </div>
                        <div class="card-body pb-1">
                            <p class="card-text">All the best for your new project.</p>
                            <p class="card-text">
                                Please make sure to read our
                                <a href="https://pixinvent.com/demo/vuexy-angular-admin-dashboard-template/documentation/"
                                    target="_blank">Template Documentation</a>
                                to understand where to go from here and how to use our template.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Basic Alerts end -->
        <!-- Marketplace Artworks -->
        <section id="card-demo-example">
            <div class="row match-height">
                <div class="col-md-6 col-lg-4">
                    <div class="card">
                        <img class="card-img-top" src="assets/images/slider/04.jpg" alt="Card image cap">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center mb-1">
                                <div class="media">
                                    <div class="avatar mr-1">
                                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" width="42"
                                            height="42">
                                    </div>
                                    <div class="media-body">
                                        <h5 class="mb-0">Mittie Evans</h5>
                                        <small class="text-muted">Updated 12m ago</small>
                                    </div>
                                </div>
                                <div class="badge badge-pill badge-light-primary">Design</div>
                            </div>
                            <div class="row justify-content-between">
                                <h3 class="col-md-9 card-title">Pink Time</h3>
                                <h4 class="col-md-3 text-success">25 ETH</h4>
                            </div>
                            <p class="card-text">
                                Some quick example text to build on the card title and make up the bulk of the card's
                                content.
                            </p>
                            <div class="demo-inline-spacing">
                                <a href="javascript:void(0)" class="btn btn-outline-primary waves-effect">Go
                                    somewhere</a>
                                <button type="button" class="btn btn-primary" rippleEffect>Buy This</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="card">
                        <img class="card-img-top" src="assets/images/slider/03.jpg" alt="Card image cap">
                        <div class="card-body">
                            <h4 class="card-title">Card title</h4>
                            <p class="card-text">
                                Some quick example text to build on the card title and make up the bulk of the card's
                                content.
                            </p>
                            <div class="demo-inline-spacing">
                                <a href="javascript:void(0)" class="btn btn-outline-primary waves-effect">Go
                                    somewhere</a>
                                <button type="button" class="btn btn-primary" rippleEffect>Primary</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="card">
                        <img class="card-img-top" src="assets/images/slider/02.jpg" alt="Card image cap">
                        <div class="card-body">
                            <h4 class="card-title">Card title</h4>
                            <p class="card-text">
                                Some quick example text to build on the card title and make up the bulk of the card's
                                content.
                            </p>
                            <div class="demo-inline-spacing">
                                <a href="javascript:void(0)" class="btn btn-outline-primary waves-effect">Go
                                    somewhere</a>
                                <button type="button" class="btn btn-primary" rippleEffect>Primary</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Marketpalce Artworks End-->
        <!-- Marketplace Artist -->
        <!-- <section id="card-demo-example">
            <div class="row match-height">
                <div class="col-md-4 col-lg-3">
                    <div class="card card-profile">
                        <img src="assets/images/banner/banner-12.jpg" alt="Profile Cover Photo"
                            class="img-fluid card-img-top">
                        <div class="card-body">
                            <div class="profile-image-wrapper">
                                <div class="profile-image">
                                    <div class="avatar"><img src="assets/images/portrait/small/avatar-s-9.jpg"
                                            alt="Profile Picture"></div>
                                </div>
                            </div>
                            <h3>Curtis Stone</h3>
                            <h6 class="text-muted">Malaysia</h6>
                            <div class="badge badge-light-primary profile-badge">Pro Level</div>
                            <hr class="mb-2">
                            <div class="d-flex justify-content-between align-items-center">
                                <div>
                                    <h6 class="text-muted font-weight-bolder">Followers</h6>
                                    <h3 class="mb-0">10.3k</h3>
                                </div>
                                <div>
                                    <h6 class="text-muted font-weight-bolder">Projects</h6>
                                    <h3 class="mb-0">156</h3>
                                </div>
                                <div>
                                    <h6 class="text-muted font-weight-bolder">Rank</h6>
                                    <h3 class="mb-0">23</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-lg-3">
                    <div class="card card-profile">
                        <img src="assets/images/banner/banner-11.jpg" alt="Profile Cover Photo"
                            class="img-fluid card-img-top">
                        <div class="card-body">
                            <div class="profile-image-wrapper">
                                <div class="profile-image">
                                    <div class="avatar"><img src="assets/images/portrait/small/avatar-s-8.jpg"
                                            alt="Profile Picture"></div>
                                </div>
                            </div>
                            <h3>Curtis Stone</h3>
                            <h6 class="text-muted">Malaysia</h6>
                            <div class="badge badge-light-primary profile-badge">Pro Level</div>
                            <hr class="mb-2">
                            <div class="d-flex justify-content-between align-items-center">
                                <div>
                                    <h6 class="text-muted font-weight-bolder">Followers</h6>
                                    <h3 class="mb-0">10.3k</h3>
                                </div>
                                <div>
                                    <h6 class="text-muted font-weight-bolder">Projects</h6>
                                    <h3 class="mb-0">156</h3>
                                </div>
                                <div>
                                    <h6 class="text-muted font-weight-bolder">Rank</h6>
                                    <h3 class="mb-0">23</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-lg-3">
                    <div class="card card-profile">
                        <img src="assets/images/banner/banner-10.jpg" alt="Profile Cover Photo"
                            class="img-fluid card-img-top">
                        <div class="card-body">
                            <div class="profile-image-wrapper">
                                <div class="profile-image">
                                    <div class="avatar"><img src="assets/images/portrait/small/avatar-s-7.jpg"
                                            alt="Profile Picture"></div>
                                </div>
                            </div>
                            <h3>Curtis Stone</h3>
                            <h6 class="text-muted">Malaysia</h6>
                            <div class="badge badge-light-primary profile-badge">Pro Level</div>
                            <hr class="mb-2">
                            <div class="d-flex justify-content-between align-items-center">
                                <div>
                                    <h6 class="text-muted font-weight-bolder">Followers</h6>
                                    <h3 class="mb-0">10.3k</h3>
                                </div>
                                <div>
                                    <h6 class="text-muted font-weight-bolder">Projects</h6>
                                    <h3 class="mb-0">156</h3>
                                </div>
                                <div>
                                    <h6 class="text-muted font-weight-bolder">Rank</h6>
                                    <h3 class="mb-0">23</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-lg-3">
                    <div class="card card-profile">
                        <img src="assets/images/banner/banner-9.jpg" alt="Profile Cover Photo"
                            class="img-fluid card-img-top">
                        <div class="card-body">
                            <div class="profile-image-wrapper">
                                <div class="profile-image">
                                    <div class="avatar"><img src="assets/images/portrait/small/avatar-s-6.jpg"
                                            alt="Profile Picture"></div>
                                </div>
                            </div>
                            <h3>Curtis Stone</h3>
                            <h6 class="text-muted">Malaysia</h6>
                            <div class="badge badge-light-primary profile-badge">Pro Level</div>
                            <hr class="mb-2">
                            <div class="d-flex justify-content-between align-items-center">
                                <div>
                                    <h6 class="text-muted font-weight-bolder">Followers</h6>
                                    <h3 class="mb-0">10.3k</h3>
                                </div>
                                <div>
                                    <h6 class="text-muted font-weight-bolder">Projects</h6>
                                    <h3 class="mb-0">156</h3>
                                </div>
                                <div>
                                    <h6 class="text-muted font-weight-bolder">Rank</h6>
                                    <h3 class="mb-0">23</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
        <!-- Marketpalce Artist End-->
        <!-- Carousel Test -->
        <!-- NG-Carousel -->
        <!-- <ngu-carousel #myCarousel [inputs]="carouselTile" [dataSource]="carouselItems" (carouselLoad)="carouselTileLoad($event)"> -->
        <ngu-carousel #myCarousel [inputs]="carouselTile" [dataSource]="slides">
            <ngu-tile *nguCarouselDef="let item; let j = index">
                <!-- <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Card title</h4>
                        <h6 class="card-subtitle text-muted">Support card subtitle</h6><img
                            [src]="item" alt="Card image cap" class="img-fluid my-2">
                        <p class="card-text">Bear claw sesame snaps gummies chocolate.</p><a
                            href="javascript:void(0)" class="card-link pink">Card link</a><a
                            href="javascript:void(0)" class="card-link pink">Another link</a>
                    </div>
                </div> -->
                <div class="card card-profile">
                    <img [src]="item.background" alt="Profile Cover Photo"
                        class="img-fluid card-img-top">
                    <div class="card-body">
                        <div class="profile-image-wrapper">
                            <div class="profile-image">
                                <div class="avatar"><img [src]="item.avatar"
                                        alt="Profile Picture"></div>
                            </div>
                        </div>
                        <h3>{{item.artist}}</h3>
                        <h6 class="text-muted">Malaysia</h6>
                        <div class="badge badge-light-primary profile-badge">Pro Level</div>
                        <hr class="mb-2">
                        <div class="d-flex justify-content-between align-items-center">
                            <div>
                                <h6 class="text-muted font-weight-bolder">Followers</h6>
                                <h3 class="mb-0">10.3k</h3>
                            </div>
                            <div>
                                <h6 class="text-muted font-weight-bolder">Projects</h6>
                                <h3 class="mb-0">156</h3>
                            </div>
                            <div>
                                <h6 class="text-muted font-weight-bolder">Rank</h6>
                                <h3 class="mb-0">23</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </ngu-tile>            

            <button NguCarouselPrev class="btn-sm btn-icon btn-outline-primary rounded-circle leftRs" style="z-index: 999; " [style.opacity]="myCarousel.isFirst ? 0.5:1"><span [data-feather]="'arrow-left'"></span></button>           
            <button NguCarouselNext class="btn-sm btn-icon btn-outline-primary rounded-circle rightRs" [style.opacity]="myCarousel.isLast ? 0.5:1"><span [data-feather]="'arrow-right'"></span></button>
            <ul class="myPoint" NguCarouselPoint>
                <li *ngFor="let j1 of myCarousel.pointNumbers; let j2 = index" [class.active]="j1==myCarousel.activePoint" (click)="myCarousel.moveTo(j1)"
                    [style.background]="'url(' + carouselTileItems[j1] + ')'">
                </li>
            </ul>
        </ngu-carousel>
        <!-- NG-Carousel End -->
        <!-- ngb-carousel component -->
        <ngb-carousel *ngIf="carouselImages">
            <ng-template ngbSlide>
                <div class="row">
                    <div class="col-md-6 card">
                        <div class="card-body">
                            <h4 class="card-title">Card title</h4>
                            <h6 class="card-subtitle text-muted">Support card subtitle</h6><img
                                src="assets/images/slider/01.jpg" alt="Card image cap" class="img-fluid my-2">
                            <p class="card-text">Bear claw sesame snaps gummies chocolate.</p><a
                                href="javascript:void(0)" class="card-link pink">Card link</a><a
                                href="javascript:void(0)" class="card-link pink">Another link</a>
                        </div>
                    </div>
                    <div class="col-md-6 card">
                        <div class="card-body">
                            <h4 class="card-title">Card title</h4>
                            <h6 class="card-subtitle text-muted">Support card subtitle</h6><img
                                src="assets/images/slider/02.jpg" alt="Card image cap" class="img-fluid my-2">
                            <p class="card-text">Bear claw sesame snaps gummies chocolate.</p><a
                                href="javascript:void(0)" class="card-link pink">Card link</a><a
                                href="javascript:void(0)" class="card-link pink">Another link</a>
                        </div>
                    </div>
                </div>
            <div class="carousel-caption"></div>
            </ng-template>
            <ng-template ngbSlide>
                <div class="row">
                    <div class="col-md-6 card">
                        <div class="card-body">
                            <h4 class="card-title">Card title</h4>
                            <h6 class="card-subtitle text-muted">Support card subtitle</h6><img
                                src="assets/images/slider/02.jpg" alt="Card image cap" class="img-fluid my-2">
                            <p class="card-text">Bear claw sesame snaps gummies chocolate.</p><a
                                href="javascript:void(0)" class="card-link pink">Card link</a><a
                                href="javascript:void(0)" class="card-link pink">Another link</a>
                        </div>
                    </div>
                    <div class="col-md-6 card">
                        <div class="card-body">
                            <h4 class="card-title">Card title</h4>
                            <h6 class="card-subtitle text-muted">Support card subtitle</h6><img
                                src="assets/images/slider/03.jpg" alt="Card image cap" class="img-fluid my-2">
                            <p class="card-text">Bear claw sesame snaps gummies chocolate.</p><a
                                href="javascript:void(0)" class="card-link pink">Card link</a><a
                                href="javascript:void(0)" class="card-link pink">Another link</a>
                        </div>
                    </div>
                </div>
            <div class="carousel-caption"></div>
            </ng-template>
            <ng-template ngbSlide>
                <div class="row">
                    <div class="col-md-6 card">
                        <div class="card-body">
                            <h4 class="card-title">Card title</h4>
                            <h6 class="card-subtitle text-muted">Support card subtitle</h6><img
                                src="assets/images/slider/03.jpg" alt="Card image cap" class="img-fluid my-2">
                            <p class="card-text">Bear claw sesame snaps gummies chocolate.</p><a
                                href="javascript:void(0)" class="card-link pink">Card link</a><a
                                href="javascript:void(0)" class="card-link pink">Another link</a>
                        </div>
                    </div>
                    <div class="col-md-6 card">
                        <div class="card-body">
                            <h4 class="card-title">Card title</h4>
                            <h6 class="card-subtitle text-muted">Support card subtitle</h6><img
                                src="assets/images/slider/04.jpg" alt="Card image cap" class="img-fluid my-2">
                            <p class="card-text">Bear claw sesame snaps gummies chocolate.</p><a
                                href="javascript:void(0)" class="card-link pink">Card link</a><a
                                href="javascript:void(0)" class="card-link pink">Another link</a>
                        </div>
                    </div>
                </div>
            <div class="carousel-caption"></div>
            </ng-template>
        </ngb-carousel>
        <!-- ngb-carousel component -->
        <!-- Carousel Test End -->
    </div>
</div>