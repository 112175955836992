<div class="content-wrapper">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Basic Alerts start -->
    <section id="sample-page">
      <div class="row" *ngIf="">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">{{ 'SAMPLE.AWESOME' | translate }}</h4>
            </div>
            <div class="card-body pb-1">
              <p class="card-text">This is your second page.</p>
              <p class="card-text">
                Chocolate sesame snaps pie carrot cake pastry pie lollipop muffin. Carrot cake dragée chupa chups
                jujubes. Macaroon liquorice cookie wafer tart marzipan bonbon. Gingerbread jelly-o dragée chocolate.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Basic Alerts end -->
  </div>
</div>
